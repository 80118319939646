body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f6f8 !important;
}
::-webkit-scrollbar {
  scrollbar-width: thin;
}
* {
  scrollbar-color: rgba(34, 34, 34, 0.07) transparent;
  scrollbar-width: thin !important;
}

* ::-webkit-scrollbar-track {
  background-color: unset !important;
}

* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: unset !important;
}

* ::-webkit-scrollbar-thumb {
  background-color: rgba(34, 34, 34, 0.2);
  border-radius: 70px
}

/* all Modal scroll css */
.modalscroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent !important;
  box-shadow: inset 0 0 6px transparent !important;
  background-color: unset !important;
}

.modalscroll::-webkit-scrollbar {
  width: 7px !important;
  height: 10px !important;
  background-color: unset !important;
}

.modalscroll::-webkit-scrollbar-thumb {
  border-radius: 70px;
  background-color: rgba(34, 34, 34, 0.);
}

.lock-scroll {
  overflow: hidden;
}
