@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url(http://fonts.googleapis.com/earlyaccess/droidarabickufi.css);

.MuiTypography-root {
  font-family: 'Poppins', sans-serif !important;
}

.MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-family: 'Poppins', sans-serif !important;
}

button {
  font-family: 'Poppins', sans-serif !important;
}


.App {
  text-align: center;
  background-color: #f5f6f8;
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif,
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-size: '16px';
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  overflow-anchor: none;
}

button.rec.rec-dot.rec.rec-dot_active {
  background: #f0493e;
  box-shadow: #f0493e 0px 0px 1px 3px;
}

.image-gallery-slide .image-gallery-image {
  transition: transform .2s
}

.image-gallery-slide .image-gallery-image:hover {
  transform: scale(2.5)
}

.imagehover:hover {
  transform: scale(1.5)
}

hr {
  width: 20% !important;
  border-style: dotted none none !important;
  border-width: 3px !important;
  background: none !important;
}

.video-wrapper {
  width: 100% !important;
  object-fit: contain;
  padding: 107px 0px !important;
  height: 350px;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.shimmer-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: shimmer 1s infinite;
  width: 100%;
}

.shimmer-card-image {
  width: 100%;
  height: 200px;
  background-color: #e8e8e8;
  margin-bottom: 16px;
}

.modelNumber{
  font-weight: 600;
  color: #F0493E;
  text-align: left;
}

.shimmer-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shimmer-card-title {
  width: 30%;
  height: 16px;
  background-color: #e8e8e8;
  margin-bottom: 8px;
}

.shimmer-card-description {
  width: 30%;
  height: 12px;
  background-color: #e8e8e8;
}

.cardGrid{
  display: flex;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
