.form-control:focus {
  border-color: #f0493e !important;
  box-shadow: none !important;
}

.react-tel-input .form-control {
  background: #e3e5e9 !important;
  border: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.track-line {
  height: 2px !important;
}

.dot {
  height: 10px;
  width: 10px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0px;
  border-radius: 50%;
  display: inline-block
}

.big-dot {
  height: 25px;
  width: 25px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  border-radius: 50%;
  display: inline-block;
}

.big-dot i {
  font-size: 12px;
}