.borderTops{
    border-top: 1px solid #c5c4c4;
 }
 .borderRights{
     border-right : 1px solid #c5c4c4;    
     position: sticky;
     top: 60px;
     /* height: 100vh; */
 }
 .filter{
    color: #000000;
    text-align: left;
    font-size: 17px;
    margin-top: 33px;
 }
 .shop_price{
    font-size: 13px;
    text-align: left;
    margin-top: 18px;
 }
 .sort-controller{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
 }
 .shop-inner{
    padding-left: 16px;
 }
 .brand{
    margin-top: 26px;
    margin-bottom: 8px;
 }
 .box{
   width: 16px;
   height: 16px;
   border: 1px solid #C2C2C2;
   margin-top: 10px;
 }