.categoryList{
    padding: 0px;
    list-style: none;
}
.hFooter{
    color: #020202;
     margin-bottom: 20px;
}
.categoryList li{
    color:#020202;
    font-size: 14px;
    text-decoration: none;
}
.border-footer{
    width: 90% !important;
    margin: 0px auto;
}
.categoryList li {
    line-height: 32px;
    cursor: pointer;
}
.socialSec img{
    width: 42px;
    margin: 0px 2px;
    height: 24px;
    object-fit: contain;
}
.socialIconPlay{
    width: 79%;
    margin-bottom: 10px;
}
.copyrights{
    color: #0B0A0A;
    margin-top: 22px;
    text-align: left;
    font-size: 17px;
    margin-bottom: 40px;
}
.footerBorder{
    border-top: 3px solid #F0493E;
    padding-top: 40px;
    margin-top: 80px;
    background-color: #FFFFFF;
}