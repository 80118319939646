.App {
    background-color: #F5F6F8;
}

.rec.rec-carousel {
}

.image-gallery-slide .image-gallery-image {
    width: 45% !important;
    object-fit: contain;
    padding: 107px 0px !important;
    height: 350px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 85% !important;
    height: 105px !important;
    line-height: 0;
    object-fit: contain;
    padding: 0px 5px !important;
}

.image-gallery-thumbnail:hover {
    outline: none;
    border: 2px solid #337ab7 !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {

    border: 2px solid #337ab7 !important;
}

.image-gallery-slide.center {
    position: relative;
    background: white;
}

.image-gallery-swipe {
    width: 80%;
}

.productName {
    color: #030202;
    font-size: 17px;
    font-weight: 400;
}

.productSec {
    display: flex;
    justify-content: space-between;
}

.Instock {
    color: #16861F;
}

.outOfStock {
    color: red;
}

.washName {
    color: #000000;
    font-size: 20px;
    text-align: left;
    font-weight: 600;
}

.oldPrice {
    margin-right: 11px;
    text-decoration: line-through;
    text-align: left;
}

.originalPrice {
    color: #F0493E;
    text-align: left;
}

.prices {
    display: flex;
    margin-top: 16px;
}

.view_plan {
    background-color: #F0493E;
    color: #fff;
}

.instaPlan {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 12px 22px;
    align-items: center;
    box-shadow: 8px 16px 32px #00000029;
    margin-top: 25px;
}

.instaPlan p {
    margin: 0px;
}

.view_plan {
    background-color: #F0493E;
    color: #fff;
    border: none;
    font-weight: 600;
    padding: 12px 25px;
    font-size: 15px;
}

.productColor {
    /* display: flex; */
    /* justify-content: space-between; */
    background-color: #fff;
    padding: 12px 22px;
    align-items: center;
    /* box-shadow: 8px 16px 32px #00000029;     */
    margin-top: 25px;
    text-align: left;
}

.paraColor {
    width: 100%;
    font-weight: 500;
    text-align: left;
    margin: 1.5em 0 1em 0.5em;
}

.productColor img {
    width: 58px;
    height: 58px;
    margin: 0px 8px;
    padding: 5px 6px;
}

.activeColor {
    border: 1px solid #F0493E;

}

.capacitySec {
    display: flex;
}

.capacity {
    border: 1px solid #CDD0D6;
    height: 50px;
    width: 60px;
    border-radius: 4px;
    color: #030202;
    padding: 5px;
    cursor: pointer
}

.productCapacity {
    background-color: #fff;
    padding: 20px 15px;
    align-items: center;
    text-align: left;
}

.activeCapacity {
    border: 1px solid #F0493E;
}

.productDelivery {
    background-color: #fff;
    padding: 14px 22px;
    align-items: center;
    margin-top: 25px;
    text-align: left;
    display: flex
}

.delivery {
    color: #030202;
    margin: 0px;
}

.deliveryDate {
    color: #16861F;
    margin: 0px;
    margin-left: 14px;
    font-size: 14px;

}

.warranty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    width: 60%;
    margin-top: 47px;
}

.return {
    width: 25%;
}

.warrantyImg {
    width: 30%;
}

.between {
    width: 32px;
    height: 2px;
    background-color: #CBCBCB;
}

.warrantyIn p,
.returnIn p {
    color: #030202;
    font-size: 14px;
    margin-top: 5px;
}

.btn_red {
    background-color: #F0493E !important;
    box-shadow: 8px 16px 32px #00000029;
    border-radius: 5px !important;
    color: #fff !important;
    padding: 13px 22px !important;
    margin-right: 30px;
}

.btn_red img {
    width: 27px;
    margin-left: 8px;
}

.btn_grey {
    background-color: #CBCBCB !important;
    box-shadow: 8px 16px 32px #00000029;
    border-radius: 5px !important;
    padding: 13px 22px !important;
}

.btn_grey img {
    width: 27px;
    margin-left: 8px;
}

.wish_btns {
    margin-top: 40px;
}

.accordions {
    margin-top: 60px;
}

.review_txt {
    color: #030202;
    text-align: left;
    margin-top: 30px;
    font-size: 18px;
    margin-bottom: 33px;
}

.start_ratings {
    display: flex;
    margin-bottom: 1em;
}

.rate_star {
    display: flex;
    justify-content: flex-start;
    background-color: #F0493E;
    color: #fff;
    height: 20px;
    font-size: 12px;
    text-align: center;
    padding: 1px 5px;
    border-radius: 2px;
}

.start {
    width: 13px;
    height: 13px;
    margin-top: 2px;
    margin-left: 4px;
}

.ratingTxt {
    text-align: left;
    padding-left: 12px;
    color: #707070;
}

.placeholder_sec img {
    width: 85px;
    margin-right: 17px;
}

.placeholder_sec {
    margin-bottom: 35px;
    margin-left: 13px;
    text-align: left;
}

.details_name {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BCBCBC;
    padding-bottom: 18px;
    margin-bottom: 21px;
}

.fully {
    color: #707070;
    text-align: left;
    font-size: 15px;
}
