.nav-item{
    color: #030202 !important;
    font-size: 15px !important;
}

.navbar-expand-lg .navbar-nav .nav-link { 
    padding: 4px 18px !important;
}
.tech-btn{
    border: 1px solid #F0493E;
    background-color: #fff;
    border-radius: 5px;
    color: #F0493E !important;
    box-shadow: 8px 16px 32px #00000029;
}
.borders{
    border-bottom: 1.5px solid #b9b9b9;  
      position: sticky;
      top: 0;
    background-color: white;
    z-index: 2;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid #F0493E !important;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.navbar-expand-lg .navbar-nav .nav-link{
    color: #030202 !important;
    font-size: 15px !important;
    font-weight: 600;
}
.dropdown-item { 
    color: #030202 !important; 
    font-size: 14px;
    font-weight: 600 !important;
    padding: 6px 38px !important;
    padding-left: 17px !important;
}
.logout{
    color: #F0493E !important;
}
.dropdown-toggle::after { 
    vertical-align: 0.195em !important;
}

.invoiceBtn{
    margin-top: 1em;
    height: 50px;
    background: #6c757d;
    width: 100%;
    color: #fff
}

.invoiceBtn:hover{
    margin-top: 1em;
    height: 50px;
    background: #6c757d;
    width: 100%;
    color: #fff
}